import {Box, Stack} from "@chakra-ui/react";
import Image from "next/image";
import PropTypes from "prop-types";

import BMLogoBlack from "../../public/BM-Logo-black.svg";
import BMLogoWhite from "../../public/BM-Logo-white.svg";

function BMLogoBox({children, imageHeight, imageWidth, colorMode}) {
    return (
        <Stack direction='row' align='center'>
            <Box>
                <Image src={colorMode === 'light'? BMLogoBlack : BMLogoWhite} height={imageHeight} width={imageWidth} alt="Boutique Mate Logo"/>
            </Box>
            {children}
        </Stack>
    )
}

BMLogoBox.propTypes = {
    imageHeight: PropTypes.number,
    imageWidth: PropTypes.number
}

BMLogoBox.defaultProps = {
    imageHeight: 100,
    imageWidth: 100,
}

export default BMLogoBox