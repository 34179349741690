import {Flex, Heading, Link, Stack, Text, useColorModeValue, VStack} from '@chakra-ui/react'
import Head from 'next/head'
import PropTypes from "prop-types";

import BMLogoBox from "../components/BMLogo/BMLogoBox";
import PageWrapper from '../components/sections/PageWrapper'


export default function LandingPage({
                                        title,
                                        subtitle,
                                        ctaLink,
                                        ctaText,
                                        ...rest
                                    }) {
    const text = useColorModeValue('light', 'dark')
    return (
        <>
            <Head>
                <link rel="icon" href="/favicon.svg"/>

                <title>Boutique Mate Events</title>
                <meta name="description" content="Boutique Mate Events"/>

                <meta property="og:url" content="https://development.events.boutiquemate.com/"/>
                <meta property="og:type" content="website"/>
                <meta property="og:site_name" content="Boutique Mate Events"/>
                <meta property="og:title" content="Boutique Mate Events"/>
                <meta property="og:description" content="Boutique Mate Events"/>
                <meta property="og:image" content=""/>


                <meta name="twitter:card" content="summary_large_image"/>
                <meta property="twitter:domain" content="development.events.boutiquemate.com"/>
                <meta property="twitter:url" content="https://development.events.boutiquemate.com/"/>
                <meta name="twitter:title" content="Boutique Mate Events"/>
                <meta name="twitter:description" content="Boutique Mate Events"/>
                <meta name="twitter:image" content=""/>
            </Head>
            <PageWrapper>
                <Flex
                    align="center"
                    justify={{base: "center", md: "space-around", xl: "space-between"}}
                    direction={{base: "column-reverse", md: "row"}}
                    wrap="no-wrap"
                    minH="70vh"
                    px={8}
                    mb={16}
                    {...rest}
                >
                    <Stack
                        spacing={4}
                        w={{base: "80%", md: "40%"}}
                        align={["center", "center", "flex-start", "flex-start"]}
                    >
                        <Heading
                            as="h1"
                            size="xl"
                            fontWeight="bold"
                            color="primary.800"
                            textAlign={["center", "center", "left", "left"]}
                        >
                            {title}
                        </Heading>
                        <Heading
                            as="h2"
                            size="md"
                            color="primary.800"
                            opacity="0.8"
                            fontWeight="normal"
                            lineHeight={1.5}
                            textAlign={["center", "center", "left", "left"]}
                        >
                            {subtitle}
                        </Heading>
                        <Text
                            fontSize="xs"
                            mt={2}
                            textAlign="center"
                            color="primary.800"
                            opacity="0.6"
                        >
                            More at: <Link href={ctaLink}>{ctaLink}</Link>
                        </Text>
                    </Stack>
                    <VStack p={4}>
                        <BMLogoBox/>
                    </VStack>
                </Flex>
            </PageWrapper>
        </>
    )
}

LandingPage.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    ctaText: PropTypes.string,
    ctaLink: PropTypes.string,
}


export async function getStaticProps(context) {
    return {
        props: {
            title: "Boutique Mate Events Page",
            subtitle:
                "Find all events powered by Boutique Mate",
            ctaText: "Create your account now",
            ctaLink: "https://boutiquemate.com",
        }
    }
}
