import { Flex, useColorMode } from '@chakra-ui/react'

function PageWrapper(props) {
  const { colorMode } = useColorMode()

  const bgColor = { light: 'gray.50', dark: 'gray.900' }

  const color = { light: 'black', dark: 'white' }
  return (
    <Flex      
      maxW='1024px'
      direction="column"
      mx='auto'
      // alignItems="center"
      // bg={bgColor[colorMode]}
      // color={color[colorMode]}
      {...props}
    />
  )
}

export default PageWrapper